import React from "react"

const Arrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.748" height="26.162" viewBox="0 0 33.748 26.162">
        <g id="Group_516" data-name="Group 516" transform="translate(-1668 -2352.793)">
            <path id="Path_2186" data-name="Path 2186" d="M6646.667,12009.333l12.374,12.374-12.374,12.374" transform="translate(-4958 -9655.833)" fill="none" stroke="#000"/>
            <path id="Path_2187" data-name="Path 2187" d="M6604,12021.707h32.167" transform="translate(-4935.5 -9655.207)" fill="none" stroke="#000"/>
        </g>
    </svg>
)

export default Arrow