import React, { useState } from "react"
import InputRange from "react-input-range"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"
import $ from "jquery"

import Arrow from "../components/images/arrow"

const ApartmentFiltring = ({ apartmentList }) => {
  const highestArea = Math.ceil(
      Math.max(...apartmentList.map(item => item.node.acf.area))
    ),
    lowestArea = Math.floor(
      Math.min(...apartmentList.map(item => item.node.acf.area))
    )

  const [areaRange, setAreaRange] = useState({
    value: { min: lowestArea, max: highestArea },
  })
  const [apartmentFilter, setApartmentFilter] = useState({
    areaStart: lowestArea,
    areaEnd: highestArea,
    rooms: [],
    floors: [],
    status: [],
  })
  let filteredApartments = apartmentList

  const setRange = value => {
    setAreaRange({ value })
    setApartmentFilter({
      ...apartmentFilter,
      areaStart: value.min,
      areaEnd: value.max,
    })
  }

  const setFloor = floorNumber => {
    if (apartmentFilter.floors.includes(floorNumber)) {
      let floors = apartmentFilter.floors
      const indexOfItem = apartmentFilter.floors.indexOf(floorNumber)

      floors.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, floor: floors })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        floors: [...apartmentFilter.floors, floorNumber],
      })
    }
  }

  const setRooms = roomNumber => {
    if (apartmentFilter.rooms.includes(roomNumber)) {
      let rooms = apartmentFilter.rooms
      const indexOfItem = apartmentFilter.rooms.indexOf(roomNumber)

      rooms.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, rooms: rooms })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        rooms: [...apartmentFilter.rooms, roomNumber],
      })
    }
  }

  const setStatus = status => {
    if (apartmentFilter.status.includes(status)) {
      let statusList = apartmentFilter.status
      const indexOfItem = apartmentFilter.status.indexOf(status)

      statusList.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, status: statusList })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        status: [...apartmentFilter.status, status],
      })
    }
  }

  const apartmentsFiletering = () => {
    filteredApartments = apartmentList.filter(item => {
      const floor = parseInt(item.node.acf.floor),
        room = parseInt(item.node.acf.roomsNumber)

      if (
        item.node.acf.area >= apartmentFilter.areaStart &&
        item.node.acf.area <= apartmentFilter.areaEnd &&
        (apartmentFilter.floors.includes(floor) ||
          apartmentFilter.floors.length === 0) &&
        (apartmentFilter.rooms.includes(room) ||
          apartmentFilter.rooms.length === 0) &&
        (apartmentFilter.status.includes(item.node.acf.status) ||
          apartmentFilter.status.length === 0)
      ) {
        return item
      }
      return false
    })
  }

  const scroll = () => {
    $("html, body").animate(
      {
        scrollTop: $("#form").offset().top - 100,
      },
      750
    )
  }

  return (
    <section className="apartment-filtering">
      {apartmentsFiletering()}
      <div className="container">
        <div className="row apartment-filtering__filter mb-70">
          <div className="col-12 col-md-6 col-lg-3 col-xl-2 col-xxl-3 fiter__area filter__item">
            <p className="mb-20 font-regular">Metraż</p>
            <div className="filter__area">
              <p className="area__min bold">
                {areaRange.value.min}m<sup>2</sup>
              </p>
              <InputRange
                allowSameValues={false}
                maxValue={highestArea}
                minValue={lowestArea}
                value={areaRange.value}
                onChange={value => setRange(value)}
              />
              <p className="area__max bold">
                {areaRange.value.max}m<sup>2</sup>
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-3 col-xxl-3 offset-xxl-1 filter__item filter__item--container">
            <div className="item__holder">
              <p className="mb-20 font-regular">Ilość pokoi</p>
              <div className="filter__list">
                <button
                  onClick={() => setRooms(1)}
                  className={`list__item nav-button ${
                    apartmentFilter.rooms.includes(1)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  1
                </button>
                <button
                  onClick={() => setRooms(2)}
                  className={`list__item nav-button ${
                    apartmentFilter.rooms.includes(2)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  2
                </button>
                <button
                  onClick={() => setRooms(3)}
                  className={`list__item nav-button ${
                    apartmentFilter.rooms.includes(3)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  3
                </button>
                <button
                  onClick={() => setRooms(4)}
                  className={`list__item nav-button ${
                    apartmentFilter.rooms.includes(4)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  4
                </button>
                <button
                  onClick={() => setRooms(5)}
                  className={`list__item nav-button ${
                    apartmentFilter.rooms.includes(5)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  5
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 filter__item filter__item--container">
            <div className="item__holder">
              <p className="mb-20 font-regular">Piętro</p>
              <div className="filter__list">
                <button
                  onClick={() => setFloor(0)}
                  className={`list__item nav-button ${
                    apartmentFilter.floors.includes(0)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  0
                </button>
                <button
                  onClick={() => setFloor(1)}
                  className={`list__item nav-button ${
                    apartmentFilter.floors.includes(1)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  1
                </button>
                <button
                  onClick={() => setFloor(2)}
                  className={`list__item nav-button ${
                    apartmentFilter.floors.includes(2)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  2
                </button>
                <button
                  onClick={() => setFloor(3)}
                  className={`list__item nav-button ${
                    apartmentFilter.floors.includes(3)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  3
                </button>
                <button
                  onClick={() => setFloor(4)}
                  className={`list__item nav-button ${
                    apartmentFilter.floors.includes(4)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  4
                </button>
                <button
                  onClick={() => setFloor(5)}
                  className={`list__item nav-button ${
                    apartmentFilter.floors.includes(5)
                      ? "nav-button--active"
                      : ""
                  } `}
                >
                  5
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-2 col-xxl-2 filter__item ">
            <p className="mb-20 font-regular">Status</p>
            <div className="filter__list filter__list--status">
              <button
                onClick={() => setStatus("available")}
                className={`list__item list__item--available nav-button ${
                  apartmentFilter.status.includes("available")
                    ? "nav-button--active"
                    : ""
                } `}
              >
                W
              </button>
              <button
                onClick={() => setStatus("reserved")}
                className={`list__item list__item--reserved nav-button ${
                  apartmentFilter.status.includes("reserved")
                    ? "nav-button--active"
                    : ""
                } `}
              >
                Z
              </button>
              <button
                onClick={() => setStatus("sold")}
                className={`list__item list__item--sold nav-button ${
                  apartmentFilter.status.includes("sold")
                    ? "nav-button--active"
                    : ""
                } `}
              >
                S
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="apartment-filtering__table">
              <div className="table__row">
                <div className="table__col">
                  <p className="bold col__text">Nr lokalu</p>
                </div>
                <div className="table__col">
                  <p className="bold col__text">Pokoje</p>
                </div>
                <div className="table__col">
                  <p className="bold col__text">Metraż</p>
                </div>
                <div className="table__col">
                  <p className="bold col__text">Piętro</p>
                </div>
                <div className="table__col">
                  <p className="bold col__text">Usytuowanie</p>
                </div>
                <div className="table__col">
                  <p className="bold col__text">
                    Cena za m<sup>2</sup>
                  </p>
                </div>
                <div className="table__col">
                  <p className="bold col__text">Cena</p>
                </div>
                <div className="table__col">
                  <p className="bold col__text">Status</p>
                </div>
                <div className="table__col">
                  <p className="bold col__text">Moliwość podzielenia</p>
                </div>
                <div className="table__col"></div>
              </div>
              {filteredApartments.length !== 0 ? (
                filteredApartments
                  .sort(
                    (a, b) =>
                      a.node.acf.apartment_number - b.node.acf.apartment_number
                  )
                  .map((item, index) => {
                    let status = ""

                    if (item.node.acf.status === "available") {
                      status = "Wolne"
                    } else if (item.node.acf.status === "reserved") {
                      status = "Zarezerwowane"
                    } else {
                      status = "Sprzedane"
                    }

                    return (
                      <Fade cascade direction={"left"} key={index} triggerOnce>
                        <Link
                          to={item.node.path}
                          className="table__item"
                          key={index}
                        >
                          <div className="table__row">
                            <div className="table__col">
                              <p className="col__text">
                                {item.node.acf.apartment_number}
                              </p>
                            </div>
                            <div className="table__col">
                              <p className="col__text">
                                {item.node.acf.roomsNumber}
                              </p>
                              <span className="divided-label">
                                {item.node.acf.rooms_divided &&
                                  item.node.acf.rooms_divided}
                              </span>
                            </div>
                            <div className="table__col">
                              <p className="col__text">
                                {item.node.acf.area.replace(".", ",")}m
                                <sup>2</sup>
                              </p>
                              <span className="divided-label">
                                {item.node.acf.surface_divided &&
                                  item.node.acf.surface_divided}
                              </span>
                            </div>
                            <div className="table__col">
                              <p className="col__text">{item.node.acf.floor}</p>
                            </div>
                            <div className="table__col">
                              <p className="col__text">
                                {item.node.acf.location}
                              </p>
                            </div>
                            <div className="table__col">
                              {/* {item.node.acf.status !== "sold" ? (
                                <p className="col__text">
                                  {item.node.acf.price_for_square_meter.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    " "
                                  )}{" "}
                                  zł
                                </p>
                              ) : (
                                ""
                              )} */}
                            </div>
                            <div className="table__col">
                              {/* {item.node.acf.status !== "sold" ? (
                                <>
                                  {item.node.acf.regular_price !== null &&
                                  item.node.acf.regular_price !== "" ? (
                                    <p className="col__text col__text--discount">
                                      {item.node.acf.regular_price.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        " "
                                      )}{" "}
                                      zł
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  <p className="col__text">
                                    {item.node.acf.price.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      " "
                                    )}{" "}
                                    zł
                                  </p>
                                </>
                              ) : (
                                ""
                              )} */}
                            </div>
                            <div className="table__col">
                              <p
                                className={`col__text col__text--${item.node.acf.status}`}
                              >
                                {status}
                              </p>
                            </div>
                            <div className="table__col">
                              {item.node.acf.divided ? (
                                <span className="tick"></span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="table__col table__col--last">
                              <Arrow />
                            </div>
                          </div>
                        </Link>
                      </Fade>
                    )
                  })
              ) : (
                <p className="table__info">
                  Brak mieszkań spełniających wybrane kryteria. Zmień parametry
                  wyszukiwania lub{" "}
                  <a href="#form" onClick={() => scroll()}>
                    skontaktuj się z nami
                  </a>{" "}
                  - chętnie pomożemy w dokonaniu najlepszego wyboru.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ApartmentFiltring
