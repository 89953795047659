import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import "../assets/styles/pages/_page-home.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import ApartmentFiltring from "../components/apartmentFiltring"
// import BuildingView from "../components/buildingView"

const Apartments = () => {
  const AcfQuery = useStaticQuery(
    graphql`
      query Apartments {
        allWordpressWpApartments {
          edges {
            node {
              id
              title
              slug
              path
              acf {
                apartment_number
                area
                floor
                location
                price
                price_for_square_meter
                roomsNumber
                regular_price
                status
                divided
                rooms_divided
                surface_divided
              }
            }
          }
        }
        allWordpressWpApartmentsTwo {
          edges {
            node {
              id
              title
              slug
              path
              acf {
                apartment_number
                area
                floor
                location
                price
                price_for_square_meter
                roomsNumber
                regular_price
                status
                divided
                rooms_divided
                surface_divided
              }
            }
          }
        }
      }
    `
  )

  const apartmentList = AcfQuery.allWordpressWpApartments.edges
  const apartmentListTwo = AcfQuery.allWordpressWpApartmentsTwo.edges

  // const apartmentStatuses = apartmentList.map(item => {
  //   return {
  //     apartment_number: item.node.acf.apartment_number,
  //     status: item.node.acf.status,
  //     area: item.node.acf.area,
  //   }
  // })

  const [tabIndex, setTabIndex] = useState(1)
  return (
    <Layout>
      <SEO title="Mieszkania" />
      <Banner title="Mieszkania" />
      <section className="apartment-tab" id="apartments">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xxl-8 offset-lg-3 offset-xxl-2">
              <h2 className="apartment-tab__title">Lista mieszkań</h2>
            </div>
            <div className="col-12 col-lg-3 col-xxl-2 apartment-tab__tab">
              <button
                onClick={() => setTabIndex(1)}
                className={`nav-button nav-button--list ${
                  tabIndex === 1 ? "nav-button--active" : ""
                }`}
              >
                Etap I
              </button>
              {apartmentListTwo.length > 2 && (
                <button
                  onClick={() => setTabIndex(2)}
                  className={`nav-button nav-button--list ${
                    tabIndex === 2 ? "nav-button--active" : ""
                  }`}
                >
                  Etap II
                </button>
              )}
            </div>
          </div>
        </div>
        {/* {tabIndex === 1 ? (
          <h2 className="apartment-tab__subtitle">
            Etap I - zakończenie prac IV kwartał 2021{" "}
          </h2>
        ) : (
          <h2 className="apartment-tab__subtitle">
            Etap II - zakończenie IV kwartał 2023
          </h2>
        )} */}
      </section>
      {tabIndex === 1 && <ApartmentFiltring apartmentList={apartmentList} />}
      {apartmentListTwo.length > 2 && tabIndex === 2 && (
        <ApartmentFiltring apartmentList={apartmentListTwo} />
      )}
      <div className="container specific-container">
        {tabIndex === 1 && apartmentListTwo.length > 2 && (
          <a href="#apartments">
            {" "}
            <button
              onClick={() => setTabIndex(2)}
              className="nav-button nav-button--list specific-button"
            >
              Zobacz Etap II
            </button>
          </a>
        )}

        {tabIndex === 2 && (
          <a href="#apartments">
            {" "}
            <button
              onClick={() => setTabIndex(1)}
              className="nav-button nav-button--list specific-button"
            >
              Zobacz Etap I
            </button>
          </a>
        )}
      </div>
    </Layout>
  )
}

export default Apartments
